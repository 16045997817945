var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-subtitle-2 pa-1 blue--text blue lighten-5"},[_vm._v(" 「次回更新」は、自動更新中のみ表示されます。「更新する」を全てOFFにした場合、自動更新を停止します。 ")]),_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"（検索）","single-line":"","hide-details":"","clearable":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.propsSitelist,"items-per-page":50,"footer-props":{
        'items-per-page-options': [50, 100, -1],
        'items-per-page-text': '表示件数',
      },"mobile-breakpoint":0,"item-key":"uk","no-data-text":"対応媒体が設定されていません"},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+" ～"+_vm._s(props.pageStop)+"件／全"+_vm._s(props.itemsLength)+"件 ")]}},{key:"item.is_auto",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"mx-1",on:{"click":function($event){return _vm.onChangeListIsAuto(item)}}},[(item.is_auto)?_c('v-icon',{staticClass:"is_auto_cursor font-bold",attrs:{"color":"primary","large":""}},[_vm._v("mdi-toggle-switch")]):_c('v-icon',{staticClass:"is_auto_cursor font-bold",attrs:{"large":""}},[_vm._v(" mdi-toggle-switch-off ")])],1)]}},{key:"item.next_update",fn:function(ref){
      var item = ref.item;
return [(_vm.working_is_auto)?_c('div',[(item.is_auto)?_c('div',[_c('span',{staticClass:"next_time"},[_vm._v(_vm._s(_vm.utilDate.getTimeFormat(item.next_update)))]),_c('br'),_c('span',{staticClass:"mini next_date"},[_vm._v(_vm._s(_vm.utilDate.getDateFormatShortDisplay(item.next_update)))])]):_c('div',{staticClass:"auto_off"},[_vm._v("--")])]):_c('div',{staticClass:"auto_off"},[_vm._v("--")])]}},{key:"item.interval_minute",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"pa-1"},[_c('span',{staticClass:"text-align-middle",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onChangeInterval(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")]),_vm._v("   "),_c('span',{staticClass:"text-align-middle"},[_vm._v(_vm._s(item.interval_minute)+"分")])],1)])]}},{key:"item.content_name",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{attrs:{"dense":""}},[_vm._v(" "+_vm._s(item.content_icon)+" ")]),_c('span',{staticClass:"text-align-middle mini"},[_vm._v(" "+_vm._s(item.content_name))])]}},{key:"item.site_content_config",fn:function(ref){
      var item = ref.item;
return [(
            _vm.setting.working_status_required_config_ids.includes(
              item.site_content_id
            )
          )?_c('div',{staticClass:"pa-1"},[(item.config_check)?[_c('span',{staticClass:"text-align-middle",staticStyle:{"cursor":"pointer"}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-circle-outline")])],1)]:[_c('span',{staticClass:"text-align-middle",staticStyle:{"cursor":"pointer"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle-outline")])]}}],null,true)},[_c('span',[_vm._v("\"更新設定\"にてコンテンツの個別設定を確認してください")])])],1)]],2):_vm._e()]}},{key:"item.site_name",fn:function(ref){
          var item = ref.item;
return [_c('span',{style:(item.color.length != 0 ? 'color:' + item.color : '')},[_vm._v(_vm._s(item.site_name))])]}},{key:"item.last_update",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"mini"},[_vm._v(_vm._s(_vm.utilDate.getDatetimeFormatLongDisplay(item.last_update)))])]}}])})],1),_c('v-dialog',{attrs:{"scrollable":"","persistent":"","width":"100%","max-width":"750px"},model:{value:(_vm.intervalDialog),callback:function ($$v) {_vm.intervalDialog=$$v},expression:"intervalDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.site_name)+"「"+_vm._s(_vm.site_content_name)+"」の更新に関する設定")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.cancelIntervalDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('ValidationObserver',{ref:"observerInterval"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"mt-0"},[_vm._v("更新間隔")]),_c('p',[_vm._v("更新間隔を設定して下さい。")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8","sm":"3"}},[_c('ValidationProvider',{attrs:{"name":"更新間隔(分)","rules":"required|max:4|between:" +
                          _vm.min_interval +
                          "," +
                          _vm.max_interval},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
                          var valid = ref.valid;
return [_c('v-text-field',{attrs:{"name":"name","label":"更新間隔","error-messages":errors,"success":valid,"suffix":"分","type":"number","outlined":""},model:{value:(_vm.interval),callback:function ($$v) {_vm.interval=$$v},expression:"interval"}})]}}])})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"mt-0"},[_vm._v("ランダム更新")]),_c('p',{staticClass:"pl-0"},[_vm._v(" ONにすると毎回決まった更新時刻ではなく、ランダムに更新更新を設定します（次回更新時刻+1分〜9分の間で変動） "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.is_random_enable),expression:"!is_random_enable"}],staticClass:"pl-0 text-red"},[_c('br'),_vm._v("※この媒体はランダム更新の設定を変更できません。 ")])]),_c('v-switch',{attrs:{"disabled":!_vm.is_random_enable,"label":("" + (_vm.is_random ? 'ON' : 'OFF'))},model:{value:(_vm.is_random),callback:function ($$v) {_vm.is_random=$$v},expression:"is_random"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("更新時間帯")]),_c('p',[_vm._v("更新する時間帯をONにして下さい。")]),_vm._l((_vm.time_zone_default_list),function(num){return _c('div',{key:num,staticClass:"time_zone_box"},[_c('v-btn',{staticClass:"pa-0 mr-1 mb-1",attrs:{"color":_vm.time_zone_list.find(function (v) { return v == num; })
                        ? "primary"
                        : "grey lighten-3","small":"","elevation":"0"},on:{"click":function($event){return _vm.onClickTimeZone(num)}}},[_vm._v(_vm._s(num)+"時")])],1)}),_c('p',[_vm._v("※店舗管理の更新時間帯で設定した内容が優先されます。")])],2)],1)],1),(
              _vm.setting.working_status_required_config_ids.includes(
                _vm.site_content_id
              )
            )?_c('div',[_c('v-divider',{staticClass:"mt-4 mb-8"}),_c('h3',[_vm._v(_vm._s(_vm.site_content_name)+"の個別設定")]),_c('p',[_vm._v(" 該当コンテンツに関する設定を行なって下さい。更新時、デフォルの設定として使用します。"),_c('span',{staticClass:"text-red"},[_vm._v("未設定の場合、自動更新が開始できません。")])]),_c('div',[_c(_vm.siteContent.component,{ref:"refsiteContentComponent",tag:"component",attrs:{"com_data":_vm.siteContent}})],1)],1):_vm._e()],1)],1),_c('v-card-actions',[_c('v-container',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('general-button',{attrs:{"btn_color":"gray","btn_block":""},on:{"click-event":_vm.cancelIntervalDialog},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close-circle")])]},proxy:true}])},[_vm._v("キャンセル")])],1),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('general-button',{attrs:{"btn_type":"info","btn_block":""},on:{"click-event":_vm.onSubmitIntervalDialog},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")])]},proxy:true}])},[_vm._v("保存")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }